import { Component, Input, OnChanges, ElementRef, AfterViewInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss']
})
export class DonutComponent implements AfterViewInit, OnChanges {
  private ctx: CanvasRenderingContext2D ;
  @Input() value = 100;
  @Input() label = 'Jira';
  @Input() size = 150;
  @Input() padding = 15;

  constructor(private elementRef: ElementRef) { }

  private createCanvas(): void {
    const canvas = document.createElement('canvas');
    canvas.width = this.size;
    canvas.height = this.size;
    this.ctx = canvas.getContext('2d');
    this.elementRef.nativeElement.appendChild(canvas);
  }
  private drawDonut() {
    const texts = this.label.split(' ');
    const percentage = this.value / 100;
    const startAngle = 270 * (Math.PI / 180);
    this.ctx.beginPath();
    this.ctx.arc(this.size / 2, this.size / 2, this.size / 2 - 2 * this.padding, startAngle, startAngle + 2 * Math.PI);
    this.ctx.lineWidth = 10;
    this.ctx.strokeStyle = '#f5f5f5';
    this.ctx.stroke();
    this.ctx.beginPath();
    this.ctx.arc(this.size / 2, this.size / 2, this.size / 2 - 2 * this.padding, startAngle, startAngle + percentage * 2 * Math.PI);
    this.ctx.lineWidth = 10;
    this.ctx.strokeStyle = '#ff1100';
    this.ctx.stroke();
    this.ctx.closePath();
    texts.forEach((text, index, array ) => {
      this.ctx.font = '900 10px Rubik';
      this.ctx.textAlign = 'center';
      this.ctx.fillText(text.toUpperCase(), this.size / 2, this.size / 2 + (index * 10 ) + 5 - ((array.length - 1) * 10) / 2 ) ;
    });
  }
  private redraw(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.size, this.size);
      this.drawDonut();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.redraw();
  }
  ngAfterViewInit(): void {
    (document as any).fonts.ready.then(() => {
      this.createCanvas();
      this.drawDonut();
    });
  }
}
