import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadarComponent } from './radar.component';

@NgModule({
  declarations: [RadarComponent],
  imports: [
    CommonModule
  ],
  exports: [RadarComponent]
})
export class RadarModule { }
