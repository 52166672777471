import { Component, OnInit } from '@angular/core';
import { StoreService } from './core/services/store/store.service';
import { Title } from '@angular/platform-browser';
import { Toaster, ToastNotificationsConfig } from 'ngx-toast-notifications';
enum view {
  bio = 'bio',
  tags = 'tags',
  details = 'details',
  skills = 'skills',
  radar = 'radar',
  store = 'store'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public confirmVisible = false;
  public fileName: string = '';
  public title = 'BoostHigh CV Creator';
  public cvs: string[] = [];
  public dev = {
    name: 'Mirosław',
    surname: 'Technologiczny',
    // tslint:disable-next-line: max-line-length
    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ultricies mollis lacus, a pulvinar lacus efficitur vel. Aliquam maximus tincidunt nibh at posuere. Suspendisse feugiat tristique elit, eget auctor quam porta in. Duis porta metus sapien, at venenatis nunc accumsan vitae. Proin fringilla tellus tellus, quis maximus velit pellentesque id. Sed sed ante elementum felis tristique ornare sed nec velit. Morbi varius at sem sed cursus. In id vulputate sapien. Etiam et nulla a arcu congue cursus. Vivamus eget viverra dui. Nam tortor libero, dignissim at pellentesque et, vulputate fermentum massa. ',
    tags: [
      { name: 'Javascript' },
      { name: 'Typescript' }
    ],
    knowledge: [
      { name: 'Database', value: 10 },
      { name: 'Design', value: 70 },
      { name: 'Programming', value: 70 },
      { name: 'Frontend', value: 90 },
      { name: 'Backend', value: 30 },
      { name: 'Hardware', value: 50 }
    ],
    details: [
      {
        title: 'Technologies',
        params: [
          { name: 'JavaScript', value: 4 },
          { name: 'TypeScript', value: 4 }
        ]
      },
      {
        title: 'Software',
        params: [
          { name: 'Linux', value: 4 },
          { name: 'Microsoft IIS', value: 4 }
        ]
      }, {
        title: 'Others',
        params: [
          { name: 'Microsoft IIS', value: 4 }
        ]
      },

    ],
    skills: [
      { name: 'GIT', value: 70 },
      { name: 'Jira', value: 50 },
      { name: 'English', value: 60 },
      { name: 'Scrum', value: 60 },
    ]
  };
  public sideTabs = [
    { label: 'Bio', icon: 'ion-ios-person', active: true, view: view.bio },
    { label: 'Tags', icon: 'ion-ios-list', active: false, view: view.tags },
    { label: 'Radar', icon: 'ion-ios-paper', active: false, view: view.radar },
    { label: 'Details', icon: 'ion-ios-star', active: false, view: view.details },
    { label: 'Skills', icon: 'ion-ios-pie', active: false, view: view.skills },
    { label: 'Saved data', icon: 'ion-ios-save', active: false, view: view.store }
  ];
  public view = view.bio;
  private toastConfig: ToastNotificationsConfig = {
    position: 'top-center',
    autoClose: true,
    type: 'dark',
    duration: 3000,
    preventDuplicates: true
  };

  constructor(private store: StoreService, private titleService: Title, private toaster: Toaster) {
    titleService.setTitle(this.title);
  }
  public abortConfirm(): void {
    this.confirmVisible = false;
  }
  public showConfirm(): void {
    this.confirmVisible = true;
  }
  public setSideTab(selectedTab): void {
    this.sideTabs.forEach((tab: any) => tab.active = false);
    selectedTab.active = true;
    this.view = selectedTab.view;
  }
  public addTag(): void {
    this.dev.tags.push({name: ''});
  }
  public removeTag(index: number): void {
    this.dev.tags.splice(index, 1);
  }
  public removeParam(detailIndex: number, paramIndex: number): void {
    this.dev.details[detailIndex].params.splice(paramIndex, 1);
  }
  public removeSection(sectionIndex: number): void {
    this.dev.details.splice(sectionIndex, 1);
  }
  public addParam(detailIndex: number): void {
    this.dev.details[detailIndex].params.push({ name: '', value: 1 });
  }
  public addDetail(): void {
    this.dev.details.push({
      title: '',
      params: [
        { name: '', value: 1 }
      ]
    }, );
  }
  public addSkill(): void {
    this.dev.skills.push({ name: '', value: 20 }, );
  }
  public removeSkill(skillIndex: number): void {
    this.dev.skills.splice(skillIndex, 1);
  }
  public addKnowledge(): void {
    this.dev.knowledge.push({ name: '', value: 20 }, );
  }
  public removeKnowledge(skillIndex: number): void {
    this.dev.knowledge.splice(skillIndex, 1);
  }
  public load(cvName: string): void {
    this.dev = JSON.parse(this.store.load(cvName));
    this.fileName = cvName;
    this.toaster.open('Loaded ' + this.fileName, this.toastConfig);
    this.titleService.setTitle(this.fileName);
  }
  public save(fileName?: string): void {
    this.titleService.setTitle(this.fileName);
    const name = this.fileName ? this.fileName : this.createNeWFileName();
    this.fileName = name;
    if (!this.cvs.find(cvName => name === cvName)) {
      this.cvs.push(name);
    }
    this.store.save('cvs', JSON.stringify(this.cvs));
    this.store.save(name, JSON.stringify(this.dev));
    this.confirmVisible = false;
    this.toaster.open('Saved ' + this.fileName, this.toastConfig);
  }
  public removeCv(cvName: string): void {
    if (this.cvs.find(name => name === cvName)) {
      this.cvs.splice(this.cvs.findIndex(name => name === cvName), 1);
      this.store.remove(cvName);
    }
    this.store.save('cvs', JSON.stringify(this.cvs));
  }
  private autoLoad(): void {
    this.cvs = this.store.load('cvs') ? JSON.parse(this.store.load('cvs')) : [];
    if (this.cvs.length) {
      const cvName = this.cvs[this.cvs.length - 1];
      this.fileName = cvName;
      this.dev = JSON.parse(this.store.load(cvName));
    }
    this.titleService.setTitle(this.fileName);
    this.toaster.open('Auto loaded ' + this.fileName, this.toastConfig);
  }
  public print(): void {
    this.titleService.setTitle(this.fileName);
    window.print();
  }
  private createNeWFileName(): string {
    return 'cv-' + new Date().toISOString().substr(0,16).replace('T', '-');   
  }
  ngOnInit() {
    this.autoLoad();
  }
}
