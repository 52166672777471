import { Component, Input, ElementRef, AfterViewInit, IterableDiffers, DoCheck } from '@angular/core';

@Component({
  selector: 'app-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.scss']
})
export class RadarComponent implements AfterViewInit, DoCheck {
  @Input() size = 1000;
  @Input() padding = 400;
  @Input() values = [];
  private ctx: CanvasRenderingContext2D ;
  constructor(private elementRef: ElementRef, private differs: IterableDiffers) { }
  private pointFromCenter(degrees: number, r: number) {
    const startAngle = 270;
    const angle = degrees * (Math.PI / 180) +  startAngle * (Math.PI / 180);
    const center =  this.size / 2;
    const x = r * Math.cos(angle) + center;
    const y = r * Math.sin(angle) + center;
    return {x, y};
  }
  private drawRadar(): void {
    const degrees = 360 / this.values.length;
    const nets = 4;
    for (let n = 4; n > 0; n--) {
      const region = new Path2D();
      this.values.forEach((value, i) => {
        // tslint:disable-next-line: no-shadowed-variable
        let r = (this.size - this.padding)  / 2;
        r = r / 1 / nets * n;
        if (i === 0) {
          region.moveTo(this.pointFromCenter(degrees * i, r).x, this.pointFromCenter(degrees * i, r).y);
        }
        region.lineTo(this.pointFromCenter(degrees * i + degrees, r).x, this.pointFromCenter(degrees * i + degrees, r).y);

      });
      region.closePath();
      if (n === 4) {
        this.ctx.fillStyle = '#fff';
        this.ctx.fill(region, 'evenodd');
      }
      this.ctx.lineWidth = 6;
      this.ctx.setLineDash([20, 20]);
      this.ctx.strokeStyle = '#E8E8E8';
      this.ctx.stroke(region);
    }
    const chart = new Path2D();
    const r = (this.size - this.padding)  / 2;
    const l = r * this.values[this.values.length - 1].value / 100;
    let x1 = this.pointFromCenter(degrees * (this.values.length - 1) + degrees, l).x;
    let y1 = this.pointFromCenter(degrees * (this.values.length - 1) + degrees, l).y;
    chart.moveTo(x1, y1);
    this.values.forEach((value, i) => {
      // tslint:disable-next-line: no-shadowed-variable
      const l = r * value.value / 100;
      chart.lineTo(this.pointFromCenter(degrees * i + degrees, l).x, this.pointFromCenter(degrees * i + degrees, l).y);
      x1 = this.pointFromCenter(degrees * i + degrees, r).x;
      y1 = this.pointFromCenter(degrees * i + degrees, r).y;
    });
    chart.closePath();
    this.ctx.lineWidth = 20;
    this.ctx.setLineDash([20, 0]);
    this.ctx.lineJoin = 'round';
    this.ctx.strokeStyle = '#ff1100';
    this.ctx.stroke(chart);

    this.ctx.closePath();

    this.values.forEach((value, i) => {
      this.ctx.beginPath();
      this.ctx.arc(this.pointFromCenter(degrees * i + degrees, r).x, this.pointFromCenter(degrees * i + degrees, r).y, 20, 0, 2 * Math.PI);
      this.ctx.fillStyle = '#ff1100';
      this.ctx.fill();
      this.ctx.strokeStyle = '#ffffff';
      this.ctx.lineWidth = 10;
      this.ctx.stroke();
      this.ctx.closePath();
      this.ctx.beginPath();
      this.ctx.font = '900 30px Rubik';
      const p = r + 50;
      this.ctx.fillStyle = '#000';

      let textAlign = 'center';
      const x = this.pointFromCenter(degrees * i + degrees, p).x;

      if (Math.round(x) > Math.round(this.size / 2)) {
        textAlign = 'start';
      }
      if (Math.round(x) < Math.round(this.size / 2)) {
        textAlign = 'end';
      }

      this.ctx.textAlign = textAlign as CanvasTextAlign;
      this.ctx.fillText(value.name.toUpperCase(), x, this.pointFromCenter(degrees * i + degrees, p).y);
      this.ctx.closePath();
    });
  }
  private createCanvas(): void {
    const canvas = document.createElement('canvas');
    canvas.width = this.size;
    canvas.height = this.size;
    this.ctx = canvas.getContext('2d');
    this.elementRef.nativeElement.appendChild(canvas);
  }

  private redraw(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.size, this.size);
      this.drawRadar();
    }
  }
  ngAfterViewInit(): void {
    (document as any).fonts.ready.then(() => {
      this.createCanvas();
      this.drawRadar();
    });
  }
  ngDoCheck() {
    const changes = this.differs.find(this.values);
    if (changes) {
      this.redraw();
    }
  }
}
