import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() value = '0/10';
  stars: boolean[];

  getFullStars(): number {
    if (this.value) {
      const full = Number(this.value.split('/')[0]);
      return full;
    }
  }
  getMaxStars(): number {
    if (this.value) {
      const max = Number(this.value.split('/')[1]);
      return max;
    }
  }
  generateStars() {
    this.stars = [];
    const full = this.getFullStars();
    const max = this.getMaxStars();
    const empty = max - full;
    for (let i = 0; i < full; i++) {
      this.stars.push(true);
    }
    for (let i = 0; i < empty; i++) {
      this.stars.push(false);
    }
  }
  ngOnInit() {
    this.generateStars();
  }
  ngOnChanges(p: any) {
    this.generateStars();
  }
}
