import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() { }

  private storage = window.localStorage;
  public save(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
  public load(key: string): string {
    return this.storage.getItem(key);
  }
  public remove(key: string): void {
    this.storage.removeItem(key);
  }
}
